import NftClientImage from "./NftClientImage";

type Props = {
  nftImage: string | null | undefined;
  nftImageFileType?: any;
  animationUrl?: string;
  tokenUri?: string;
  nftCached?: string;
};

export default function NftImage({
  nftImage,
  nftImageFileType,
  animationUrl,
  tokenUri,
  nftCached,
}: Props) {
  let image = nftImage;

  if (!image || image === "undefined" || image.length === 0) {
    try {
      // console.log("fetching image from tokenUri: ", tokenUri);
      // image = await getNftImageFromTokenUri(tokenUri || "");
    } catch (error) {
      console.error("Failed to fetch image from tokenUri", error);
      image = null;
    }
  }

  return (
    <NftClientImage
      image={nftImage}
      animationUrl={animationUrl}
      nftCached={nftCached}
      nftImageFileType={nftImageFileType}
    />
  );
}
