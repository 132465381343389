import { currencyIds } from "@/constants/currency";
import { redis } from "@/lib/redis";
import axios from "axios";

export async function getTokenPrices(tokenIds) {
  const ids = Array.isArray(tokenIds) ? tokenIds.join(",") : tokenIds;

  try {
    const url = `https://api.coingecko.com`;
    const response = await axios.get(
      `${url}/api/v3/simple/price?ids=${ids}&vs_currencies=usd`
    );

    return response.data;
  } catch (error) {
    console.log(`Failed to fetch token prices: ${error.message}`);
    return null;
  }
}

export type CryptoPrice = Record<string, { usd: number }>;

// Example cryptoPrice object returned by getPrice:
// {
//   arbitrum: { usd: 1.068 },
//   'binance-bitcoin': { usd: 63016 },
//   bitcoin: { usd: 62948 },
//   cardano: { usd: 0.468098 },
//   dogecoin: { usd: 0.146357 },
//   ethereum: { usd: 3107.53 },
//   'matic-network': { usd: 0.7346 },
//   optimism: { usd: 2.86 },
//   ripple: { usd: 0.532812 },
//   'shiba-inu': { usd: 0.00002449 },
//   solana: { usd: 143.74 },
//   tether: { usd: 1 }
// };

export const getPrices = async (): Promise<CryptoPrice | null> => {
  const ids = currencyIds.map((currency) => currency.id).join(",");
  try {
    const prices = await getTokenPrices(ids);
    const dataObj = Object.keys(prices).reduce((acc, key) => {
      acc[key] = prices[key];
      return acc;
    }, {});

    await cachePriceData(dataObj);

    return dataObj;
  } catch (error) {
    console.error(
      "Failed to fetch crypto prices from price service / CoinGecko:",
      error.message
    );
    return null;
  }
};

export async function getCachedPriceData() {
  const cacheKey = "priceData";
  try {
    const cachedPriceData = await redis.hgetall(cacheKey);
    return cachedPriceData;
  } catch (error) {
    console.error("Failed to get price data from cache:", error);
  }
}

export async function cachePriceData(data: any) {
  const cacheKey = "priceData";
  try {
    await redis.hset(cacheKey, data);
    await redis.expire(cacheKey, 1800); // 30 minutes
  } catch (error) {
    console.error("Failed to cache collection SEO data:", error);
  }
}
