export type Network = {
  chainId: number;
  rpcUrl: string;
  name: string;
  label: string;
  alchemyUrl?: string;
  image?: string;
  currency?: string;
};

export const ETHEREUM_MAINNET: Network = {
  chainId: 1,
  rpcUrl: `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  name: "ethereum",
  label: "Ethereum",
  alchemyUrl: "eth-mainnet",
  image: `/network/1.png`,
  currency: "ETH",
};

export const ETHEREUM_SEPOLIA: Network = {
  chainId: 11155111,
  rpcUrl: `https://sepolia.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  name: "ethereum_sepolia",
  label: "Ethereum Sepolia",
  alchemyUrl: "eth-sepolia",
  image: `/network/1.png`,
  currency: "ETH",
};

export const POLYGON_MAINNET: Network = {
  chainId: 137,
  rpcUrl: `https://polygon-mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  name: "polygon",
  label: "Polygon",
  alchemyUrl: "polygon-mainnet",
  image: `/network/137.png`,
  currency: "MATIC",
};

export const POLYGON_AMOY: Network = {
  chainId: 80002,
  rpcUrl: `https://polygon-amoy.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  name: "polygon-amoy",
  label: "Polygon Amoy",
  alchemyUrl: "polygon-amoy",
  image: `/network/137.png`,
  currency: "MATIC",
};

// export const POLYGON_ZKEVM: Network = {
//   chainId: 1101,
//   rpcUrl: "", // TO DO
//   name: "polygon_zkevm",
//   label: "Polygon zkEVM",
//   currency: "MATIC",
// };

export const ARBITRUM_MAINNET: Network = {
  chainId: 42161,
  rpcUrl: `https://arbitrum-mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  name: "arbitrum",
  label: "Arbitrum",
  alchemyUrl: "arb-mainnet",
  image: `/network/42161.png`,
  currency: "ARB",
};

export const ARBITRUM_SEPOLIA: Network = {
  chainId: 421614,
  rpcUrl: `https://arbitrum-sepolia.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  name: "arbitrum-sepolia",
  label: "Arbitrum Sepolia",
  alchemyUrl: "arb-sepolia",
  image: `/network/42161.png`,
  currency: "ARB",
};

export const OPTIMISM_MAINNET: Network = {
  chainId: 10,
  rpcUrl: `https://optimism-mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  name: "optimism",
  label: "Optimism",
  alchemyUrl: "opt-mainnet",
  image: `/network/10.png`,
  currency: "OP",
};

export const OPTIMISM_SEPOLIA: Network = {
  chainId: 11155420,
  rpcUrl: `https://optimism-sepolia.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  name: "optimism-sepolia",
  label: "Optimism Sepolia",
  alchemyUrl: "opt-sepolia",
  image: `/network/10.png`,
  currency: "OP",
};

export const BASE_MAINNET: Network = {
  chainId: 8453,
  rpcUrl: "", // TO DO
  name: "base",
  label: "BASE",
  alchemyUrl: "base-mainnet",
  image: `/network/8453.png`,
  currency: "ETH",
};

export const BASE_SEPOLIA: Network = {
  chainId: 84532,
  rpcUrl: "", // TO DO
  name: "base-sepolia",
  label: "BASE Sepolia",
  alchemyUrl: "base-sepolia",
  image: `/network/8453.png`,
  currency: "ETH",
};

export const STARKNET_MAINNET: Network = {
  chainId: 1,
  rpcUrl: `https://starknet-mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  name: "starknet",
  label: "Starknet",
  alchemyUrl: "starknet-mainnet",
  currency: "STRK",
};
export const STARKNET_SEPOLIA: Network = {
  chainId: 5,
  rpcUrl: `https://starknet-sepolia.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
  name: "starknet-sepolia",
  label: "Starknet Sepolia",
  alchemyUrl: "starknet-sepolia",
  currency: "STRK",
};

export const NETWORKS = [
  ETHEREUM_MAINNET,
  ETHEREUM_SEPOLIA,
  POLYGON_MAINNET,
  POLYGON_AMOY,
  // POLYGON_ZKEVM,
  ARBITRUM_MAINNET,
  ARBITRUM_SEPOLIA,
  OPTIMISM_MAINNET,
  OPTIMISM_SEPOLIA,
  BASE_MAINNET,
  BASE_SEPOLIA,
  STARKNET_MAINNET,
  STARKNET_SEPOLIA,
];
